import axios from 'axios';
import APIUtils from './APIUtils';
import { User } from './UserUtils';
import { Enumeration } from './EnumerationUtils';

// /title, first, middle, last, etc
interface FlsActivity {
  id: number;
  activity_type: Enumeration;
  activity_type_key: string;
  request_method: Enumeration;
  request_method_key: string;
  delivery_mode: Enumeration;
  delivery_mode_key: string;
  description: string;
  source: string;
  received_on: string;
  completed_on: string;
  created_by: User;
  created_at: string;
}

interface FlsActivityFilter {
  created_by_id: number;
  from_completed_date: string;
  to_completed_date: string;
  locale: string;
}

const flsActivity = {
  generateDefault: (item?: Partial<FlsActivity>): Partial<FlsActivity> => {
    return {
      id: item?.id,
      activity_type: item?.activity_type,
      activity_type_key: item?.activity_type_key,
      request_method: item?.request_method,
      request_method_key: item?.request_method_key,
      delivery_mode: item?.delivery_mode,
      delivery_mode_key: item?.delivery_mode_key,
      description: item?.description,
      source: item?.source,
      received_on: item?.received_on,
      completed_on: item?.completed_on,
      created_by: item?.created_by,
      created_at: item?.created_at,
    };
  },

  formatName: (created_by: Partial<FlsActivity['created_by']>) => {
    return `${created_by.first_name || ''} ${
      created_by.last_name || ''
    }`.trim();
  },
};

const api = {
  list: async (params?: { point_of_service_id?: number }) => {
    const resp = await axios.get<FlsActivity[]>('fls_activity/list.php', {
      params: params,
    });
    return resp.data;
  },
  get: async (id: number) => {
    const resp = await axios.get<FlsActivity>('fls_activity/get.php', {
      params: { id: id },
    });
    return resp.data;
  },
  create: async (item: Partial<FlsActivity>) => {
    const resp = await axios.post<FlsActivity>('fls_activity/create.php', {
      ...APIUtils.clean(item),
    });
    return resp.data;
  },
  update: async (item: Partial<FlsActivity>) => {
    const resp = await axios.put<FlsActivity>('fls_activity/update.php', {
      ...APIUtils.clean(item),
    });
    return resp.data;
  },
  remove: async (id: number) => {
    const resp = await axios.delete<FlsActivity>('fls_activity/remove.php', {
      params: { id: id },
    });
    return resp.data;
  },
  export: async (filter: Partial<FlsActivityFilter>) => {
    const resp = await axios.get('fls_activity/export.php', {
      params: { ...filter },
      responseType: 'blob',
    });
    return resp.data;
  },
};

export default { api, flsActivity };
export { FlsActivity, FlsActivityFilter };
